import { CHARACTER } from 'constant';
import produce from 'immer';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import { Dispatch, SetStateAction } from 'react';
import { InfoIcon } from 'shared/components/Labeled';
import { TableCellE911Value, TableCellSeatName } from '../components/TableCell';
import { E911Row, IsLoading } from '../definition';
import translations from '../translations';

export const columns: any = () => {
  const { table } = translations();

  return [
    {
      Header: table.columns.seat.header,
      Cell: ({
        row: {
          original: { isUnassigned, seatName },
        },
      }: any) => <TableCellSeatName isUnassigned={isUnassigned} name={seatName} />,
      accessor: ({ seatName }: E911Row) => seatName || CHARACTER.EMDASH,
    },
    {
      Header: table.columns.device.header,
      accessor: ({ deviceName }: E911Row) => deviceName ?? CHARACTER.EMDASH,
    },
    {
      Header: table.columns.location.header,
      accessor: ({ locationName }: E911Row) => locationName ?? CHARACTER.EMDASH,
    },
    {
      Header: table.columns.e911.callerId.name.header,
      Cell: ({
        row: {
          original: { e911 },
        },
      }: any) => (
        <TableCellE911Value
          text={{
            missing: table.columns.e911.callerId.name.missing,
            value: e911.name,
          }}
        />
      ),
      accessor: ({ e911 }: E911Row) => e911.name || table.columns.e911.callerId.name.missing,
    },
    {
      Header: table.columns.e911.callerId.number.header,
      Cell: ({
        row: {
          original: { e911 },
        },
      }: any) => (
        <TableCellE911Value
          text={{
            missing: table.columns.e911.callerId.number.missing,
            value: e911.number,
          }}
        />
      ),
      accessor: ({ e911 }: E911Row) => e911.number || table.columns.e911.callerId.number.missing,
    },
    {
      Header: table.columns.e911.address.header,
      Cell: ({
        row: {
          original: { e911 },
        },
      }: any) => (
        <TableCellE911Value
          isSystemNumber={e911.isSystemNumber}
          number={e911.number}
          text={{
            missing: table.columns.e911.address.missing,
            value: e911.address,
          }}
        />
      ),
      accessor: ({ e911 }: E911Row) =>
        e911.number ? e911.address : table.columns.e911.address.missing,
    },
    {
      Header: (
        <>
          {table.columns.source.header.text}
          <InfoIcon isInHeader tooltip={table.columns.source.header.info} />
        </>
      ),
      accessor: ({ sourceName }: E911Row) => sourceName,
      id: 'source',
    },
  ];
};

/**
 * @name sortData
 * @description Sort by comparing E911 seats/devices `seatName` value.
 *
 * @param a Array of E911 seats/devices data
 * @param b Array of E911 seats/devices data
 *
 * @returns Sorted array of data.
 */
export const sortData = (a: Pick<E911Row, 'seatName'>, b: Pick<E911Row, 'seatName'>) => {
  if (a.seatName === '') {
    return 1;
  }
  if (b.seatName === '') {
    return -1;
  }
  return b.seatName?.localeCompare(a.seatName);
};

/**
 * @name processData
 * @description Concatenate the processed devices and user data for use in the List table.
 *
 * @param [object]
 * @property devices
 * @property locations
 * @property numbers
 * @property users
 * @property setData
 * @property setIsLoading
 *
 * @returns E911 data array.
 */

export const processData = ({
  devices,
  locations,
  numbers,
  users,
  setData,
  setIsLoading,
}: {
  devices: Array<E911Row>;
  locations: any;
  numbers: any;
  users: Array<E911Row>;
  setData: Dispatch<SetStateAction<Array<E911Row>>>;
  setIsLoading?: Dispatch<SetStateAction<IsLoading>>;
}) => {
  const { table } = translations();
  let data: Array<any> = [];

  if (devices.length > 0 && users.length > 0 && !isEmpty(locations) && !isEmpty(numbers)) {
    data = concat(users, devices)
      .sort(sortData)
      .map((item: any) =>
        produce(item, (draft: any) => {
          const { e911 = {}, location, source } = item;
          const e911Address = numbers[e911.number]?.e911?.street_address;

          if (isEmpty(draft.e911)) {
            draft.e911 = {};
          }

          if (e911Address) {
            draft.e911.address = e911Address;
          }

          if (location) {
            draft.locationName = locations[location]?.display_name;
          } else {
            delete draft.location;
          }

          draft.e911.isSystemNumber = Boolean(numbers[e911.number]);
          draft.sourceName = table.columns.source.type[source];
        }),
      );

    setData(data);
  }

  setIsLoading?.((isLoading: IsLoading) => ({ ...isLoading, data: false }));

  return data; // for testing
};

/**
 * @name processDevices
 * @description Process device data for use in the List table.
 *
 * @param [object]
 * @property usersData
 * @property setUsers
 * @property setIsLoading
 *
 * @returns Devices array.
 */
export const processDevices = ({
  devicesData,
  users,
  setDevices,
  setIsLoading,
}: {
  devicesData: any;
  users: Array<E911Row>;
  setDevices?: Dispatch<SetStateAction<Array<E911Row>>>;
  setIsLoading?: Dispatch<SetStateAction<IsLoading>>;
}) => {
  const { table } = translations();
  let data = [];

  if (devicesData?.length && users.length) {
    data = devicesData
      .filter(({ caller_id }: any) => caller_id?.emergency?.name || caller_id?.emergency?.number)
      .map(({ id, caller_id, name, owner_id }: any) => {
        const user = users.find(({ id }: any) => id === owner_id);
        return {
          id,
          deviceName: name,
          e911: caller_id?.emergency,
          isUnassigned: !user,
          location: user?.location,
          seatName: user ? user.seatName : table.columns.seat.notAssigned,
          source: 'device',
        };
      });

    setDevices?.(data);
  }

  setIsLoading?.((isLoading: IsLoading) => ({ ...isLoading, devices: false }));

  return data; // for testing
};

/**
 * @name processUsers
 * @description Process user data for use in the List table.
 *
 * @param [object]
 * @property accountData
 * @property usersData
 * @property setUsers
 * @property setIsLoading
 *
 * @returns Users array.
 */
export const processUsers = ({
  accountData,
  usersData,
  setUsers,
  setIsLoading,
}: {
  accountData: any;
  usersData: any;
  setUsers?: Dispatch<SetStateAction<Array<E911Row>>>;
  setIsLoading?: Dispatch<SetStateAction<IsLoading>>;
}) => {
  let data = [];

  if (!isEmpty(accountData) && usersData?.length) {
    data = usersData.map(({ id, caller_id, first_name, last_name, location, username }: any) => {
      const hasE911 =
        !isEmpty(caller_id?.emergency) &&
        (caller_id?.emergency.name || caller_id?.emergency.number);

      return {
        id,
        location,
        e911: hasE911 ? caller_id?.emergency : accountData.caller_id?.emergency,
        seatName: `${username}, ${first_name} ${last_name}`,
        source: hasE911 ? 'seat' : 'account',
      };
    });

    setUsers?.(data);
  }

  setIsLoading?.((isLoading: IsLoading) => ({ ...isLoading, users: false }));

  return data; // for testing
};
