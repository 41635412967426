import { useFetchAccountQuery } from 'models/Account';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useEffect, useState } from 'react';
import Alert from 'shared/components/Alert';
import Loading from 'shared/components/Loading';
import { AddressAlertProps as Props } from './definition';
import { assignAlertValues } from './utility';

const AddressAlert = ({
  hasMargin,
  isAccountLevel = false,
  number,
  setIsNumbersInUsePickerOpen,
}: Props) => {
  const [alert, setAlert] = useState<Record<string, any>>();
  const { data: accountData, isLoading: isLoadingAccount } = useFetchAccountQuery();
  const { data: numbersData, isLoading: isLoadingNumbers } = useFetchPhoneNumbersQuery();

  useEffect(() => {
    assignAlertValues({
      isAccountLevel,
      data: {
        account: accountData,
        numbers: numbersData,
      },
      number,
      setAlert,
      setIsNumbersInUsePickerOpen,
    });
  }, [isAccountLevel, number, accountData, numbersData, setAlert, setIsNumbersInUsePickerOpen]);

  if (isLoadingAccount || isLoadingNumbers) {
    return <Loading />;
  }

  return (
    <Alert
      action={alert?.action}
      className="MuiAlert-address"
      hasMargin={hasMargin}
      message={alert?.message}
      severity={alert?.severity}
    />
  );
};

export default AddressAlert;
