import { ComponentProps } from 'shared/definition';

export enum SeverityType {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export interface AddressAlertProps extends ComponentProps {
  /** Does the alert have bottom margin? */
  hasMargin?: boolean;
  /** Is this being used at the account level? */
  isAccountLevel?: boolean;
  /** Number value */
  number: string;
  /** Event handler */
  setIsNumbersInUsePickerOpen: (isOpen: boolean) => void;
}
