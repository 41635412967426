import { useFetchAccountQuery } from 'models/Account';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { useFetchUsersQuery } from 'models/User';
import { useEffect, useState } from 'react';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { E911Row, IsLoading } from './definition';
import translations from './translations';
import { columns, processData, processDevices, processUsers } from './utility';

const List = () => {
  const { table } = translations();
  const [data, setData] = useState<Array<E911Row>>([]);
  const [devices, setDevices] = useState<Array<E911Row>>([]);
  const [users, setUsers] = useState<Array<E911Row>>([]);
  const [isLoading, setIsLoading] = useState<IsLoading>({
    data: true,
    devices: true,
    users: true,
  });

  const { data: accountData, isLoading: isLoadingAccount } = useFetchAccountQuery();
  const { data: devicesData, isLoading: isLoadingDevices } = useFetchDevicesQuery();
  const { data: numbersData, isLoading: isLoadingNumbers } = useFetchPhoneNumbersQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();

  useEffect(() => {
    processDevices({ devicesData, users, setDevices, setIsLoading });
  }, [devicesData, users, table.columns.seat.notAssigned]);

  useEffect(() => {
    processUsers({ accountData, usersData, setUsers, setIsLoading });
  }, [accountData, usersData]);

  useEffect(() => {
    processData({
      devices,
      users,
      locations: accountData?.locations,
      numbers: numbersData?.usage.assigned,
      setData,
      setIsLoading,
    });
  }, [devices, accountData, numbersData, users]);

  if (
    isLoadingAccount ||
    isLoadingDevices ||
    isLoadingNumbers ||
    isLoadingUsers ||
    isLoading.devices ||
    isLoading.users ||
    isLoading.data
  ) {
    return <Loading />;
  }

  return (
    <Table
      hasSearch
      columns={columns()}
      data={data}
      hasEditIcon={false}
      hasPagination={{ rowsPerPage: true }}
      title={table.title}
    />
  );
};

export default List;
